import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "../components/Link"
import { Layout } from "../components/layout"
import {
  Hero,
  Container,
  ImageCard,
  FeatureCallout,
} from "@revfluence/aspire-www-ui"
import { PageSection } from "../components/page-section"
import SEO from "../components/seo"

const ReadMoreLink = styled.a`
  color: #1f3f8b;
  &:hover {
    text-decoration: underline;
  }
`

export default function BestPractices({ data }) {
  return (
    <Layout>
      <SEO
        title="Holiday best practices to ensure success this season"
        description="We've compiled the best practices marketers should keep in mind when planning campaigns during the most wonderful time of the year."
        url="holiday.aspireiq.com/best-practices"
      />

      <Hero size="sm">{null}</Hero>
      <Container>
        <PageSection>
          <FeatureCallout
            mediaPosition="right"
            headline="5 best practices for a successful influencer marketing holiday campaign"
            subHeadline=""
            Copy={() => (
              <div>
                <div>
                  Running a holiday campaign that does not get lost in the sea
                  of never ending sales promotions requires creativity. That is
                  why many brands turn to influencers to create dynamic content
                  that will reach targeted consumers.
                </div>
                <div>
                  Brands that are wondering how to plan and execute successful
                  holiday-themed social media campaigns, look no further. We've
                  compiled five of the best practices marketers should keep in
                  mind when planning influencer campaigns during the most
                  wonderful time of the year.
                </div>
              </div>
            )}
            Media={() => (
              <ImageCard
                css={`
                  height: 545px;
                `}
                Media={(props) => (
                  <Img fixed={data.imageOne.childImageSharp.fixed} {...props} />
                )}
              />
            )}
            Cta={(props) => (
              <Link
                to="https://www.aspireiq.com/blog/5-best-practices-for-a-holiday-campaign"
                {...props}
              >
                <ReadMoreLink>Read more</ReadMoreLink>
              </Link>
            )}
          />
        </PageSection>
        <PageSection>
          <FeatureCallout
            mediaPosition="left"
            headline="Your last-minute guide to holiday campaigns on social media"
            subHeadline=""
            Copy={() => (
              <div>
                <div>
                  Each year, holiday campaigns start earlier and earlier — and
                  for good reason. The endless amount of sales promotions can
                  make it extremely difficult for brands to stand out, but
                  influencer marketing delivers a level of authenticity that
                  helps brands cut through the noise.
                </div>
                <div>
                  In our post, learn how to tap into existing brand communities
                  to crowdsource content from loyal fans, engage across
                  channels, and keep users shopping through January.
                </div>
              </div>
            )}
            Media={() => (
              <ImageCard
                css={`
                  height: 545px;
                `}
                Media={(props) => (
                  <Img fixed={data.imageTwo.childImageSharp.fixed} {...props} />
                )}
              />
            )}
            Cta={(props) => (
              <Link
                to="https://www.aspireiq.com/blog/guide-to-holiday-campaigns-on-social-media"
                {...props}
              >
                <ReadMoreLink>Read more</ReadMoreLink>
              </Link>
            )}
          />
        </PageSection>
        <PageSection>
          <FeatureCallout
            mediaPosition="right"
            headline="Black Friday: Influencer marketing tips to add to your shopping list"
            subHeadline=""
            Copy={() => (
              <div>
                Black Friday will generate billions of dollars online this year.
                So how can your brand get a piece of the pie? It's no secret
                that influencer marketing has been used by brands to increase
                sales, generate awarenes, and drive traffic. And during the
                holiday season, this holds true. However with increased
                competitions and an influx in content consumption by consumers,
                there are a few things to keep in mind when strategizing a
                winning influencer campaign this time of year.
              </div>
            )}
            Media={() => (
              <ImageCard
                css={`
                  height: 545px;
                `}
                Media={(props) => (
                  <Img
                    fixed={data.imageThree.childImageSharp.fixed}
                    {...props}
                  />
                )}
              />
            )}
            Cta={(props) => (
              <Link
                to="https://www.aspireiq.com/blog/black-friday-influencer-marketing-tips"
                {...props}
              >
                <ReadMoreLink>Read more</ReadMoreLink>
              </Link>
            )}
          />
        </PageSection>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    imageOne: file(relativePath: { regex: "/best-practices-1/" }) {
      childImageSharp {
        fixed(width: 570, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imageTwo: file(relativePath: { regex: "/best-practices-2/" }) {
      childImageSharp {
        fixed(width: 570, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imageThree: file(relativePath: { regex: "/best-practices-3/" }) {
      childImageSharp {
        fixed(width: 570, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
